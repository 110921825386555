import React from "react"
import { Container, Grid} from 'semantic-ui-react';
import { Helmet } from "react-helmet";
import Layout from '../components/layout';
//import { Link } from "gatsby";
/*import '../styles/pages/speaking.scss';*/

/* React Album */
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  cleanup,
  useEvents,
  useLightboxState,
  useController,
  ACTION_PREV,
  ACTION_NEXT
} from "yet-another-react-lightbox/core";


import eventPhotos from "../galleries/our-work/photos";
import eventSlides from "../galleries/our-work/slides";

const SlideWrapper = ({ slide, children }) => {
    const { publish, subscribe } = useEvents();
    const {
      state: { currentIndex }
    } = useLightboxState();
    const { getLightboxProps } = useController();
    const { slides } = getLightboxProps();
  
    React.useEffect(
      () =>
        slides[currentIndex] === slide
          ? cleanup(
              subscribe(ACTION_PREV, (count) => {
                console.log("prev");
              }),
              subscribe(ACTION_NEXT, (count) => {
                console.log("next");
              })
            )
          : () => {},
      [subscribe, slides, currentIndex, slide]
    );
  
    // supported events: "close", "prev", "next"
    return <div onClick={() => publish("close")}>{children}</div>;
};
  
const OurWorkApp = () => {
    const [index, setIndex] = React.useState(-1);
  
    return(
        <Layout page='internal'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Elitepro Creative - Our Work</title>
                <meta name="description" content=""></meta>
            </Helmet>
    


        <Container> 
            <Grid stackable>
                {/*<Grid.Row>
                        <Grid.Column >
                        <Header as='h1'>Our Work</Header>
                        </Grid.Column >
               </Grid.Row>*/}
                <Grid.Row>
                  <Grid.Column>
                    <PhotoAlbum
                      photos={eventPhotos}
                      layout="columns"
                      onClick={(event, eventPhoto, index) => setIndex(index)}
                      />
                    <Lightbox
                      slides={eventSlides}
                      index={index}
                      open={index >= 0}
                      close={() => setIndex(-1)}
                      carousel={{ finite: true }}
                      render={{
                          slideContainer: (slide, children) => (
                          <SlideWrapper slide={slide} {...{ children }} />
                          )
                      }}
                      />
                  </Grid.Column>
                    
                </Grid.Row>
            
            </Grid>
            
        </Container>
    </Layout>

    );
};
  
  export default OurWorkApp;



