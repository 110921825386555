const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

/*const unsplashLink = (id, width, height) =>
  `https://source.unsplash.com/${id}/${width}x${height}`;*/

const unsplashPhotos = [
  {
    id: "ts1zXzsD7xc",
    width: 723,
    height: 577,
    src: "/gallery/ads/cw-001-Samsung.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 225,
    height: 375,
    src: "/gallery/ads/cw-002-welcome-pack.jpg"
  },  {
    id: "F_r83HEzsXI",
    width: 3304,
    height: 4192,
    src: "/gallery/ads/cw-003-free-Internet.jpg"
  },
  {
    id: "ts1zXzsD7xc",
    width: 1728,
    height: 541,
    src: "/gallery/ads/at-001-banner.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1260,
    height: 826,
    src: "/gallery/ads/at-002-brochure-front.jpg"
  },  {
    id: "F_r83HEzsXI",
    width: 1440,
    height: 968,
    src: "/gallery/ads/at-003-brochure-inside.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1440,
    height: 968,
    src: "/gallery/ads/at-004-standing-banner.jpg"
  },  
  {
    id: "F_r83HEzsXI",
    width: 2161,
    height: 1620,
    src: "/gallery/ads/at-005-page.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 576,
    height: 893,
    src: "/gallery/ads/gg-001-flyer-french.jpg"
  },  {
    id: "F_r83HEzsXI",
    width: 1089,
    height: 702,
    src: "/gallery/ads/gg-002-logo.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 600,
    height: 1451,
    src: "/gallery/ads/fy-001-brochure.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 600,
    height: 343,
    src: "/gallery/ads/fy-002-card2.jpg"
  },  {
    id: "F_r83HEzsXI",
    width: 1000,
    height: 433,
    src: "/gallery/ads/tg-001-banner.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 600,
    height: 776,
    src: "/gallery/ads/tg-002-flyer.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1725,
    height: 1127,
    src: "/gallery/ads/tg-003-sacha-glam-invi.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 3075,
    height: 1200,
    src: "/gallery/ads/tg-004-nakita-glam.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 600,
    height: 303,
    src: "/gallery/ads/tg-005-giftcertificate.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 569,
    height: 580,
    src: "/gallery/ads/tg-006-sticker-with-experience.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 600,
    height: 372,
    src: "/gallery/ads/tg-007-glame-ogo.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 526,
    height: 618,
    src: "/gallery/ads/tg-008-busCards-ross.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2400,
    height: 600,
    src: "/gallery/ads/itc-001-book-mark.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2400,
    height: 600,
    src: "/gallery/ads/itc-002-book-mark.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1800,
    height: 900,
    src: "/gallery/ads/itc-003-fest-banner.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 4790,
    height: 4031,
    src: "/gallery/ads/itc-004-fest-logo.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1500,
    height: 1200,
    src: "/gallery/ads/itc-005-inivitation.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1029,
    height: 747,
    src: "/gallery/ads/js-001-logo-final.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 750,
    height: 1200,
    src: "/gallery/ads/js-002-newspaper-advert.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2581,
    height: 1710,
    src: "/gallery/ads/kb-001-draftbeer-banner.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1260,
    height: 812,
    src: "/gallery/ads/kb-002-annual-report.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 864,
    height: 378,
    src: "/gallery/ads/nb-001-BankaNet-billboard.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2880,
    height: 1704,
    src: "/gallery/ads/nb-002-bankaNet-banner.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 3075,
    height: 4650,
    src: "/gallery/ads/nb-003-bank-newspaper.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2083,
    height: 1133,
    src: "/gallery/ads/nb-004-web-banner.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2700,
    height: 2400,
    src: "/gallery/ads/nb-005-ExcellenceAwardProgram.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1728,
    height: 579,
    src: "/gallery/ads/nb-006-BankersExchange.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1500,
    height: 2400,
    src: "/gallery/ads/nb-007-kalinago-Territory.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1687,
    height: 1275,
    src: "/gallery/ads/nb-008-fruad-brochure.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 3375,
    height: 2550,
    src: "/gallery/ads/nb-009-fruad-brochure.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 3375,
    height: 2550,
    src: "/gallery/ads/nb-010-fruad-brochure.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 576,
    height: 466,
    src: "/gallery/ads/nb-011-pens.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 1275,
    height: 1650,
    src: "/gallery/ads/nb-012-christmas-cards.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 750,
    height: 600,
    src: "/gallery/ads/nb-013-invitation.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 612,
    height: 864,
    src: "/gallery/ads/nb-014-christmas-poster.jpg"
  },
  {
    id: "F_r83HEzsXI",
    width: 2550,
    height: 3300,
    src: "/gallery/ads/nb-015-creditCardLetter.jpg"
  }
  
];

const photos = unsplashPhotos.map((photo, index) => {
  const width = photo.width * 4;
  const height = photo.height * 4;
  return {
    src: photo.src,
    key: `${index}`,
    width,
    height,
    images: breakpoints.map((breakpoint) => {
      const breakpointHeight = Math.round((height / width) * breakpoint);
      return {
        src: photo.src,
        width: breakpoint,
        height: breakpointHeight
      };
    })
  };
});

export default photos;
